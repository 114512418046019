var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"document-view"},[(
      _vm.isContractTemplate &&
      _vm.getTemplateFields.length &&
      !_vm.isProposer &&
      !_vm.isSignedByCounterparty &&
      !this.$route.params.workspace_id
    )?_c('ProgressLine',{class:{ 'fixed-progress-line': _vm.fixedProgressLine },attrs:{"total":_vm.getTemplateFields.length,"progress":_vm.filledInputsCount}}):_vm._e(),(_vm.isLoading)?_c('DocumentPageLoader'):[_c('div',{class:{ 'document-view__comments--active': _vm.canEditComments },on:{"click":_vm.createComment}},[(
          _vm.isContractTemplate &&
          _vm.getTemplateFields.length &&
          !_vm.isProposer &&
          !_vm.isSignedByCounterparty &&
          !this.$route.params.workspace_id
        )?_c('ProgressLine',{attrs:{"total":_vm.getTemplateFields.length,"progress":_vm.filledInputsCount}}):_vm._e(),(_vm.isContractTemplate)?_c('DocumentTemplateView',{attrs:{"source":_vm.contractTemplate,"contract":_vm.contract,"selectedContractId":_vm.selectedContractId}}):_vm._e(),(_vm.canShowPdfDocument)?_c('DocumentPages',{staticClass:"showImages",attrs:{"document-link":_vm.documentLink,"is-pdf-loading":_vm.isPdfLoading,"can-show-signatures":_vm.canShowSignatures,"custom-color":_vm.customColor,"sign":_vm.sign}}):_vm._e()],1),(_vm.canContinueToSigning)?_c('div',{staticClass:"pt-10 px-0"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"col-12 col-md-auto"},[_c('v-btn',{attrs:{"color":_vm.customColor,"x-large":"","min-width":"14rem","block":_vm.$vuetify.breakpoint.mobile,"loading":_vm.isSubmitted},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('general.continue_to_signing'))+" ")])],1)],1)],1):_vm._e(),_vm._l((_vm.contractComments),function(comment){return _c('CommentsPoint',{key:comment.id || `comment-${new Date().getTime()}`,attrs:{"comment":comment,"contract-id":_vm.contract.id,"can-edit-comment":_vm.canEditComments && !_vm.hasCommentModeClosed},on:{"destroy-uncreated-comment":_vm.destroyUncreatedComment,"disable-comments-creating":_vm.disableCreateNewComment,"reload-comments":_vm.reload}})})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }